.informer {
    width: 80px;
    height: 80px;
    background-color: white;
    border-radius: 15px;
    border: 2px solid #ccc;
    position: absolute;
    z-index: 5000;
    top: 150px;
    left: 10px;
}

.informer-content-wrapper {
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
}

.informer-content-figure {
    font-size: 12pt;
    font-weight: bold;
    color: brown;
    margin-top: 5px;
}
